import { DateTime } from "luxon"
import { MultistepForm } from "kui-utils"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import DurationBlock from "../DurationBlock"
import ServiceDocumentField from "../ServiceDocumentField"
import { ServiceEditingFields } from "../ServiceEditingForm/types"
import { ApartmentServiceFormStages } from "../../../../store/shared/apartmentService/ApartmentService"

const serviceMainInfoFields = (
  handleDateChange: (newDate: Date) => void,
  formMaker: MultistepForm<
    ServiceEditingFields,
    typeof ApartmentServiceFormStages
  >,
  lastOpenPeriod?: DateTime | null,
  apartmentId?: number | null
): InputParams<any>[] => [
  {
    variant: "custom",
    CustomInput: ServiceDocumentField,
    name: "file",
    size: 12,
    formMaker,
    apartmentId,
  },
  {
    label: "Name",
    testId: "name",
    name: "name",
    size: 12,
    maxLength: 210,
    isRequired: true,
  },
  {
    variant: "custom",
    name: "durationBlock",
    CustomInput: DurationBlock,
    handleDateChange,
    lastOpenPeriod,
    size: 12,
  },
]

export default serviceMainInfoFields
