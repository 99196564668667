import * as React from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { Avatar } from "kui-complex"
import styled from "@emotion/styled"
import useStore from "../../../hooks/useStore"
import DropdownMenu from "../../ui/DropdownMenu"

function AvatarDropdown() {
  const navigate = useNavigate()
  const {
    authStore: { logout },
    cabinetStore: { fullName },
  } = useStore()

  const logoutHandle = async () => {
    await logout()
    navigate("/signin")
  }

  const menuOptions = [{ label: "Logout", handleClick: logoutHandle }]

  return (
    <div>
      <StyledDropdownMenu options={menuOptions}>
        <Avatar
          size="s"
          fullName={fullName || "Unknown"}
          data-testid="avatar"
        />
      </StyledDropdownMenu>
    </div>
  )
}

export default observer(AvatarDropdown)

const StyledDropdownMenu = styled(DropdownMenu)`
  .KUI-Popper {
    width: 200px;
  }
`
