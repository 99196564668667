import { DateTime } from "luxon"
import { BillLineDocumentModel } from "../../../types/common"
import { ServicesConfirmationStatus } from "../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesServicesAPI"
import { ServiceCreationFields } from "../../../components/forms/apartmentExpenses/ServiceCreationForm/types"
import { ServiceEditingFields } from "../../../components/forms/apartmentExpenses/ServiceEditingForm/types"
import {
  ServiceModel,
  ServicesResponse,
} from "../../../types/api/apartmentExpenses"

const currentDate = DateTime.now()
const curentMonthDate = DateTime.fromObject({
  month: currentDate.month,
  year: currentDate.year,
  day: 1,
})

export const servicesStatusesIds = {
  confirmed: 1,
  unConfirmed: 2,
}

export const documentMock: BillLineDocumentModel = {
  attachment: "/mock/files/file.pdf",
  name: "Document",
  size: 1,
  pk: 1,
  created: "2022-10-15",
  uploaded_by: null,
}

export const ownerMock = {
  id: 1,
  email: "owner@mail.ru",
  first_name: "Иван",
  last_name: "Иванов",
  middle_name: "Иванович",
  full_name: "Иван Иванов Иванович",
  phone: "79111111111",
}

export const renterMock = {
  id: 1,
  email: "renter@mail.ru",
  first_name: "Петр",
  last_name: "Петров",
  middle_name: "Петрович",
  full_name: "Петр Петров Петрович",
  phone: "79111111111",
}

export const serviceUtilityMock: ServiceModel = {
  id: 1,
  line_type: "open_ended",
  purpose: "за май 2021",
  name: "Оплата квитанции ЖКХ",
  company: {
    id: 1,
    name: "Жилищное хозяйство",
  },
  company_account: {
    id: 1,
    name: "Основной счет",
  },
  payment_made_by: "owner",
  refund_from: "maroom",
  owner: ownerMock,
  renter: renterMock,
  value: "5236",
  date_of_issue: "2021-10-20",
  payment_type: "card",
  period_info: {
    charge_from: "2021-08-21",
    duration_type: "open-ended",
  },
  invoice: documentMock,
  file_number: "",
}

export const serviceFixedMock: ServiceModel = {
  id: 3,
  line_type: "period",
  purpose: "по договору №78JLS6J0909GHSLK за май 2021",
  name: "Оплата за интернет",
  company: {
    id: 3,
    name: "NewLink",
  },
  company_account: null,
  payment_made_by: "renter",
  refund_from: "maroom",
  owner: ownerMock,
  renter: renterMock,
  value: "785",
  payment_type: "card",
  date_of_issue: "2021-10-20",
  period_info: {
    charge_from: "2021-08-21",
    duration_type: "open-ended",
  },
  invoice: null,
  file_number: "",
}

export const serviceVariableTenantMock: ServiceModel = {
  id: 4,
  line_type: "once",
  purpose: "за разбитую вазу",
  name: "Порча имущества собственника",
  company: {
    id: 4,
    name: "Собственник",
  },
  company_account: {
    id: 1,
    name: "Основной счет",
  },
  payment_made_by: "renter",
  refund_from: "owner",
  renter: renterMock,
  owner: ownerMock,
  value: "8963",
  payment_type: "card",
  date_of_issue: "2021-10-20",
  period_info: {
    charge_from: "2021-08-21",
    duration_type: "once",
  },
  invoice: null,
  file_number: "",
}

export const serviceVariableLandlordMock: ServiceModel = {
  id: 5,
  line_type: "once",
  purpose: "за покраску стен",
  name: "Оттелочные работы",
  company: {
    id: 4,
    name: "Собственник",
  },
  company_account: {
    id: 1,
    name: "Основной счет",
  },
  payment_made_by: "owner",
  refund_from: "renter",
  renter: renterMock,
  owner: ownerMock,
  value: "12000",
  payment_type: "card",
  date_of_issue: "2021-10-20",
  period_info: {
    charge_from: "2021-08-21",
    duration_type: "once",
  },
  invoice: null,
  file_number: "",
}

export const confirmedStatusServices: ServicesConfirmationStatus = {
  exists: true,
}

export const unConfirmedStatusServices: ServicesConfirmationStatus = {
  exists: false,
}

export const servicesResponseMock: ServicesResponse = {
  open_ended: [serviceUtilityMock],
  period: [serviceFixedMock],
  once: [serviceVariableTenantMock, serviceVariableLandlordMock],
  operating: [],
  auto: [],
}

export const fixedServiceFieldsMock: ServiceCreationFields = {
  name: "Оплата за клининг",
  purpose: "За октябрь 2022",
  company: {
    id: 51,
    name: "Clean place",
  },
  price: 3500,
  refundFrom: "maroom",
  paymentMadeBy: "tenant",
  paymentType: "card",
  comment: "",
  chargeFrom: curentMonthDate.toJSDate(),
  durationType: "period",
  file: null,
}

export const variableServiceFieldsMock: ServiceEditingFields = {
  purpose: "New purpose",
  price: 3000,
  refundFrom: "tenant",
  paymentMadeBy: "landlord",
  name: "New name",
  paymentType: "cash",
  chargeFrom: DateTime.fromISO("2022-10-10").toJSDate(),
  durationType: "open-ended",
}
