import { MultistepForm } from "kui-utils"
import { CompanyAutocomplete } from "kui-crm"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import { ServiceEditingFields } from "../ServiceEditingForm/types"
import { ApartmentServiceFormStages } from "../../../../store/shared/apartmentService/ApartmentService"
import CompanyBankAccountAutocomplete from "../../../entityInputs/CompanyBankAccountAutocomplete"

const serviceRequisitesInfoFields = (
  handlePurposeChange: () => void,
  setSubmitDisabled: (value: boolean) => void,
  formMaker: MultistepForm<
    ServiceEditingFields,
    typeof ApartmentServiceFormStages
  >,
  companyDisabled: boolean,
  setCompanyDisabled: (disabled: boolean) => void,
  apartmentId?: number | null
): InputParams<any>[] => [
  {
    label: "Company",
    testId: "company",
    size: 12,
    variant: "custom",
    name: "company",
    CustomInput: CompanyAutocomplete,
    forApartment: apartmentId,
    setSubmitDisabled,
    disabled: companyDisabled,
  },
  {
    size: 12,
    variant: "custom",
    name: "companyBankAccount",
    CustomInput: CompanyBankAccountAutocomplete,
  },
  {
    label: "Payer code",
    name: "payerCode",
    size: 6,
    disabled: true,
  },
  {
    label: "Operational account number",
    name: "communalServicesAccount",
    size: 6,
    disabled: true,
  },
  {
    label: "Price",
    testId: "price",
    name: "price",
    size: 6,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    isRequired: true,
  },
  {
    label: "Document number",
    testId: "documentNumber",
    name: "documentNumber",
    size: 6,
  },
  {
    label: "Purpose",
    testId: "purpose",
    name: "purpose",
    size: 12,
    type: "number",
    isTextArea: true,
    onChange: handlePurposeChange,
    maxLength: 210,
    isRequired: true,
    withCounter: true,
  },
]

export default serviceRequisitesInfoFields
