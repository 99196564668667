import React, { useCallback, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import {
  LoaderState,
  useAutocomplete,
  CompanyAutocompleteFields,
} from "kui-crm"
import { useWatch } from "react-hook-form"
import InputWithAutocomplete from "../../ui/InputWithAutocomplete"
import AutocompleteOption from "../../common/AutocompleteOption"
import {
  CompanyBankAccountAutocompleteProps,
  CompanyBankAccountFields,
} from "./types"
import CompanyBankAccountsStore from "../../../store/lites/CompanyBankAccountsStore"

const CompanyBankAccountAutocomplete = <T extends object>(
  props: CompanyBankAccountAutocompleteProps<T>
) => {
  const { form, name, ...otherProps } = props
  const [isOpen, setIsOpen] = useState(false)
  const countiesStoreRef = useRef(new CompanyBankAccountsStore())

  const { bankAccounts, fetchAll, filter, paginator, loader } =
    countiesStoreRef.current
  const { isLoading } = loader
  const company: CompanyAutocompleteFields = useWatch({
    control: form.control,
    name: "company",
  })

  const fetchById = useCallback(async () => {
    if (company?.id) {
      filter.updateSearchQuery("")
      form.setValue(`${name}.id`, null)
      form.setValue(`${name}.name`, "", { shouldValidate: true })
      fetchAll(company.id)
    }
  }, [company?.id])

  const { onSearchItem } = useAutocomplete(
    filter,
    paginator,
    fetchById,
    true,
    undefined,
    undefined,
    [company?.id]
  )

  const onSelectItem = (item: CompanyBankAccountFields | null) => {
    form.setValue(`${name}.id`, item?.id)
    form.setValue(`${name}.name`, item?.name, { shouldValidate: true })
  }

  useEffect(() => {
    if (!isOpen && bankAccounts.length === 1) {
      onSelectItem(bankAccounts[0])
    }
  }, [isOpen, bankAccounts.length])

  return (
    <>
      <InputWithAutocomplete
        renderOption={(option: CompanyBankAccountFields) => (
          <AutocompleteOption label={option.name} />
        )}
        isLoading={isLoading}
        options={bankAccounts}
        label="Company bank account"
        form={form}
        name={`${name}.name`}
        hideName={`${name}.id`}
        onSelectItem={onSelectItem}
        onSearchItem={onSearchItem}
        onOpen={setIsOpen}
        {...otherProps}
      />
      <LoaderState loader={loader} onlyError />
    </>
  )
}

export default observer(CompanyBankAccountAutocomplete)
