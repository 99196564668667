import React, { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DateTime } from "luxon"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { FormBottom, InputByType, LoaderState } from "kui-crm"
import { Loader } from "kui-utils"
import { ServiceMainInfoFormFields, ServiceMainInfoFormProps } from "./types"
import ServiceFieldsSchema from "./schema"
import serviceMainInfoFields from "./fields"
import PrevServicesList from "../PrevServicesList"
import ApartmentServicesStore from "../../../../store/shared/apartmentService/ApartmentServicesStore"

function ServiceMainInfoForm(props: ServiceMainInfoFormProps) {
  const {
    formMaker,
    withPrevServicesList,
    periodId,
    apartment,
    lastOpenPeriod,
    withApartment,
  } = props
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current
  const form = useForm<ServiceMainInfoFormFields>({
    defaultValues: {
      ...formMaker.fields,
      hasRentalContract:
        formMaker.fields?.hasRentalContract || apartment?.hasRentalContract,
    },
    resolver: yupResolver(ServiceFieldsSchema),
  })
  const formApartment = formMaker.fields?.apartment
  const actualApartmentId = apartment?.id || formApartment?.id
  const actualPeriodId = periodId || formApartment?.periodId

  const handleSubmit = (data: ServiceMainInfoFormFields) => {
    formMaker.updateFormFields(data)
    formMaker.nextStep()
  }

  const handleIssueDateChange = (newDate: Date) => {
    const durationType = form.getValues("durationType")
    const apartmentFields = apartment || formApartment
    if (apartmentFields && durationType === "open-ended") {
      const purposeResult = ApartmentServicesStore.generatePurpose(
        DateTime.fromJSDate(newDate),
        apartmentFields
      )
      if (purposeResult.err) {
        form.setError("chargeFrom", {
          message: purposeResult.message,
        })
      } else {
        form.clearErrors("chargeFrom")
        formMaker.updateFormFields({ purpose: purposeResult.message })
      }
    }
  }

  useEffect(() => {
    if (formMaker.fields?.hasRentalContract) {
      form.setValue("hasRentalContract", true)
    }
  }, [formMaker.fields])

  const infoFields = serviceMainInfoFields(
    handleIssueDateChange,
    formMaker,
    lastOpenPeriod,
    actualApartmentId
  )

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box mb={3} pr={5} pl={5}>
        <Grid container spacing={2}>
          {!withApartment && withPrevServicesList && (
            <Grid item xs={12}>
              <PrevServicesList
                form={form}
                formStore={formMaker}
                apartmentId={apartment?.id}
                periodId={actualPeriodId}
              />
            </Grid>
          )}
          {infoFields.map(({ size, ...field }) => (
            <Grid item xs={size} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom label="Next" variant="next" />
      <LoaderState loader={loader} />
    </form>
  )
}

export default observer(ServiceMainInfoForm)
