import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { Loader, resHandler } from "kui-utils"
import {
  AdvertisingHistoryTick,
  CurrencyVariants,
} from "../../../../../types/store/apartmentChart"
import {
  AdvertisingPricesHistoryResponse,
  GetChartPricesResponse,
} from "../../../../../types/api/apartmentChartAPI"
import ApartmentChartTickStore from "./ApartmentChartTick"
import ApartmentChartAgent from "../../../../../../../agent/ApartmentChart"
import PromotionPageStore from "../../PromotionPageStore"
import {
  ChartTickParams,
  RentalContractTickParams,
  VolumeOfApartmentsParams,
} from "../../../types/store/apartmentChart"
import {
  GetVolumeOfApartmentsResponse,
  RentalContractTickModel,
  RentalContractTicksResponse,
  VolumeOfApartmentsModel,
} from "../../../types/api/promotionChannelsAPI"
import ApartmentPromotionAgent from "../../../../../../../agent/ApartmentPromotion"

const TICK_COUNT = 5
const TICK_SIZE = 5000

class ApartmentChartStore {
  predictorTicks: ApartmentChartTickStore[]

  currency: CurrencyVariants

  rentalContractTicks: RentalContractTickParams[]

  advertisingHistory: AdvertisingHistoryTick[]

  volumeOfApartments: VolumeOfApartmentsParams[]

  loader: Loader

  promotionStore: PromotionPageStore

  startDate: string

  endDate: string

  constructor(promotionStore: PromotionPageStore) {
    const currentDate = DateTime.now()
    this.startDate = currentDate.minus({ month: 3 }).toISODate()!
    this.endDate = currentDate.toISODate()!

    this.predictorTicks = []
    this.volumeOfApartments = []
    this.rentalContractTicks = []
    this.advertisingHistory = []
    this.currency = "RUB"
    this.loader = new Loader()
    this.promotionStore = promotionStore
    makeAutoObservable(this)
  }

  getChartInfo = async () => {
    const apartmentId = this.promotionStore.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.loader.startLoading()

      await Promise.allSettled([
        this.getHistoryById(apartmentId),
        this.getAdvertisingHistory(apartmentId),
        this.getRentalContractTicks(),
        this.getVolumeOfApartments(),
      ])

      this.loader.endLoading()
    }
  }

  getHistoryById = async (apartmentId: number) => {
    const [err, res] = await to<GetChartPricesResponse>(
      ApartmentChartAgent.getHistoryCosts(
        apartmentId,
        this.startDate,
        this.endDate
      )
    )

    runInAction(() => {
      if (res && !err) {
        const lastPrice = res[res.length - 1]?.price
        this.predictorTicks = res.map(
          (item) => new ApartmentChartTickStore(item)
        )
        if (lastPrice) {
          this.promotionStore.channelsStore.contentStore.updatePredictorPrice(
            Number(lastPrice)
          )
        }
      } else {
        this.loader.setError("fetch prices", err)
      }
    })
  }

  getAdvertisingHistory = async (apartmentId: number) => {
    const result = await to<AdvertisingPricesHistoryResponse>(
      ApartmentChartAgent.getAdvertisingHistory(
        apartmentId,
        this.startDate,
        this.endDate
      )
    )

    resHandler(result, this.loader, this.updateAdvertisingHistory)
  }

  getVolumeOfApartments = async () => {
    const response = await to<GetVolumeOfApartmentsResponse>(
      ApartmentPromotionAgent.getVolumeOfApartments(
        this.startDate,
        this.endDate
      )
    )

    resHandler(response, this.loader, (res) => {
      this.volumeOfApartments = ApartmentChartStore.getApartmentVolumeTicks(
        res.report
      )
    })
  }

  getRentalContractTicks = async () => {
    const contractId =
      this.promotionStore.apartmentStore.overviewStore.actualRentalContractId

    if (contractId) {
      const currentDate = DateTime.now()
      const startDate = currentDate.minus({ month: 3 }).toISODate()!
      const endDate = currentDate.toISODate()!

      const response = await to<RentalContractTicksResponse>(
        ApartmentPromotionAgent.getRentalContractPrices(
          contractId,
          startDate,
          endDate
        )
      )

      resHandler(response, this.loader, this.updateRentalContractTicks)
    }
  }

  updateAdvertisingHistory = (res: AdvertisingPricesHistoryResponse) => {
    this.advertisingHistory = res.history.map((history) => ({
      managerPrice: Number(history.price) || null,
      date: DateTime.fromISO(history.date),
    }))
  }

  updateRentalContractTicks = (res: RentalContractTicksResponse) => {
    this.rentalContractTicks = res.prices.map((tick) =>
      ApartmentChartStore.getRentalContractTick(tick)
    )
  }

  get predictorCosts() {
    return this.predictorTicks
      .map((item) => item.price || 0)
      .filter((item) => item)
  }

  get rcMaxCosts() {
    return this.rentalContractTicks
      .map((item) => item.maxPrice || 0)
      .filter((item) => item)
  }

  get rcMinCosts() {
    return this.rentalContractTicks
      .map((item) => item.minPrice || 0)
      .filter((item) => item)
  }

  get advertisingHistoryCosts() {
    return this.advertisingHistory
      .map((item) => item.managerPrice || 0)
      .filter((item) => item)
  }

  get minCost() {
    if (this.chartTicks.length > 0) {
      const min = Math.min(
        ...this.predictorCosts,
        ...this.rcMinCosts,
        ...this.advertisingHistoryCosts
      )
      return Math.floor(min / TICK_SIZE) * TICK_SIZE
    }
    return 0
  }

  get maxCost() {
    if (this.chartTicks.length > 0) {
      const max = Math.max(
        ...this.predictorCosts,
        ...this.rcMaxCosts,
        ...this.advertisingHistoryCosts
      )
      return Math.ceil(max / TICK_SIZE) * TICK_SIZE
    }
    return 0
  }

  get costsTicks() {
    const allTicksCount = (this.maxCost - this.minCost) / TICK_SIZE
    const tickCount =
      allTicksCount > TICK_COUNT ? TICK_COUNT : Math.floor(allTicksCount)
    const divisionSize =
      Math.floor((this.maxCost - this.minCost) / tickCount / TICK_SIZE) *
      TICK_SIZE
    const arr = new Array(tickCount).fill(0)
    return [
      ...arr.map((_, index) => this.minCost + divisionSize * index),
      this.maxCost,
    ]
  }

  get monthTicks() {
    let prevItem = 0
    const params: any = {}
    this.predictorTicks.forEach((item: any, index: number) => {
      const prev = this.predictorTicks[index - 1]?.formattedMonth
      const current = this.predictorTicks[index]?.formattedMonth

      if (current !== prev) {
        params[index] = {
          label: current,
        }
        if (params[prevItem]) {
          params[prevItem].ticksCount = index - prevItem
        }
        prevItem = index
      }
    })

    return params
  }

  get chartTicks() {
    return new Array(
      Math.max(
        this.rentalContractTicks.length,
        this.predictorTicks.length,
        this.advertisingHistory.length
      )
    )
      .fill(1)
      .map((_, index) => ({
        ...(this.predictorTicks[index] || {}),
        ...(this.rentalContractTicks[index] || {}),
        managerPrice: this.advertisingHistory[index]?.managerPrice,
      })) as ChartTickParams[]
  }

  static getApartmentVolumeTicks = (ticks: VolumeOfApartmentsModel[]) =>
    ticks.map((tick) => {
      const date = DateTime.fromISO(tick.date)
      return {
        date,
        count: tick.count,
        formattedDate: date?.toFormat("dd.MM.yy"),
      }
    })

  static getRentalContractTick = (
    tick: RentalContractTickModel
  ): RentalContractTickParams => {
    const date = tick.date ? DateTime.fromISO(tick.date) : null
    const minPrice = Number(tick.min_price)
    const maxPrice = Number(tick.max_price)

    return {
      rentalContractPrice: Number(tick.price),
      minPrice,
      maxPrice,
      date,
      formattedDate: date?.toFormat("dd.MM.yy") || "",
      formattedMonth: date?.toFormat("MMMM").toLowerCase() || "",
      range: [minPrice, maxPrice],
    }
  }
}

export default ApartmentChartStore
