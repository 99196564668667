import * as React from "react"
import { useFieldArray } from "react-hook-form"
import _ from "lodash"
import { ApartmentFillingSectionProps } from "./types"
import { FillingParams } from "../ApartmentFillingCard/types"
import ApartmentFillingSection from "../ApartmentFillingSection"
import { FillingFormFields } from "../../../../../pages/ApartmentPage/forms/filling/types"
import { FillingInfoFormFields } from "../../../../../pages/ApartmentPage/forms/filling/FillingInfoForm/types"
import { UploadFillingPhotoFields } from "../../../../../pages/ApartmentPage/forms/filling/UploadFillingPhoto/types"

function ApartmentControlledFillingSection<T extends FillingParams>(
  props: ApartmentFillingSectionProps<T>
) {
  const { form, type, ...other } = props
  const { fields, append, remove, update } = useFieldArray({
    control: form!.control,
    name: `fillingList.${type}`,
    keyName: "fieldId",
  })

  const handleAdd = (data: FillingFormFields) => {
    append(data)
  }

  const handleEdit = (
    data: FillingInfoFormFields,
    filling: FillingParams,
    index: number
  ) => {
    update(index, data)
  }

  const handleDuplicate = (filling: FillingParams) => {
    append(_.omit(filling, ["id"]))
  }

  const handleDelete = (filling: FillingParams, index: number) => {
    remove(index)
  }

  const deletePhoto = (
    filling: FillingParams,
    fillingIndex: number,
    imageIndex?: number
  ) => {
    if (typeof imageIndex === "number") {
      // @ts-ignore
      const images = fields[fillingIndex].images || []
      update(fillingIndex, {
        ...fields[fillingIndex],
        images: [
          ...images.slice(0, imageIndex),
          ...images.slice(imageIndex + 1),
        ],
      })
    }
  }

  const addPhoto = (
    filling: FillingParams,
    data: UploadFillingPhotoFields,
    index: number
  ) => {
    update(index, {
      ...fields[index],
      // @ts-ignore
      images: [...(fields[index].images || []), ...data.images],
    })
  }

  return (
    <ApartmentFillingSection
      form={form}
      // @ts-ignore
      fillingList={fields}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDuplicate={handleDuplicate}
      onDelete={handleDelete}
      onAddPhoto={addPhoto}
      onDeletePhoto={deletePhoto}
      {...other}
    />
  )
}

export default ApartmentControlledFillingSection
