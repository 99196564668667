import React, { useEffect, useRef, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { FormBottom, InputByType, LoaderState } from "kui-crm"
import { Loader } from "kui-utils"
import ServiceFieldsSchema from "./schema"
import serviceMainInfoFields from "./fields"
import ApartmentServicesStore from "../../../../store/shared/apartmentService/ApartmentServicesStore"
import {
  ServiceRequisitesInfoFormFields,
  ServiceRequisitesInfoFormProps,
} from "./types"

function ServiceRequisitesInfoForm(props: ServiceRequisitesInfoFormProps) {
  const { formMaker, apartment, periodId } = props
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [companyDisabled, setCompanyDisabled] = useState(false)
  const form = useForm<ServiceRequisitesInfoFormFields>({
    defaultValues: {
      ...formMaker.fields,
      company: formMaker.fields?.company,
      payerCode: formMaker.fields?.payerCode || apartment?.payerCode,
      communalServicesAccount:
        formMaker.fields?.communalServicesAccount ||
        apartment?.communalServicesAccount,
    },
    resolver: yupResolver(ServiceFieldsSchema),
  })
  const company = useWatch({
    control: form.control,
    name: "company",
  })
  const formApartment = formMaker.fields?.apartment
  const actualApartmentId = apartment?.id || formApartment?.id
  const actualPeriodId = periodId || formApartment?.periodId

  const handleSubmit = (data: ServiceRequisitesInfoFormFields) => {
    formMaker.updateFormFields(data)
    formMaker.nextStep()
  }

  const handlePurposeChange = () => {
    const purposeError = form.formState.errors.purpose
    if (purposeError) {
      form.clearErrors("purpose")
    }
  }

  const checkCompanyIsNew = async () => {
    if (company?.id && actualApartmentId && actualPeriodId) {
      loader.startLoading()
      const [err, isNew] =
        await ApartmentServicesStore.checkIsNewCompanyForApartment(
          company.id,
          actualPeriodId,
          actualApartmentId
        )

      if (err) {
        loader.setError("check company is new", err)
        form.clearErrors("company.name")
      } else if (isNew) {
        form.setError("company.name", {
          message: "Attention! This is a new company for the object!",
        })
      } else {
        form.clearErrors("company.name")
      }
      loader.endLoading()
    } else {
      form.clearErrors("company.name")
    }
  }

  useEffect(() => {
    checkCompanyIsNew()
  }, [company?.id, actualApartmentId])

  useEffect(() => {
    if (formMaker.fields?.company) {
      form.setValue("company.id", formMaker.fields?.company.id)
      form.setValue("company.name", formMaker.fields?.company.name)
    }
  }, [formMaker.fields])

  const infoFields = serviceMainInfoFields(
    handlePurposeChange,
    setSubmitDisabled,
    formMaker,
    companyDisabled,
    setCompanyDisabled,
    actualApartmentId
  )

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box mb={3} pr={5} pl={5}>
        <Grid container spacing={2}>
          {infoFields.map(({ size, ...field }) => (
            <Grid item xs={size} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom
        handlePrevClick={formMaker.prevStep}
        withPrevStep
        label="Next"
        variant="next"
        disabled={submitDisabled}
      />
      <LoaderState loader={loader} />
    </form>
  )
}

export default observer(ServiceRequisitesInfoForm)
