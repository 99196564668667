/** @jsxImportSource @emotion/react */
import React, { useState } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Button, Grid, Divider } from "kui-basic"
import { DotsIcon } from "kui-icon"
import { PageBreadcrumbs } from "kui-crm"
import { PageHeaderProps } from "./types"
import DropdownMenu from "../../ui/DropdownMenu"
import Container from "../../ui/Container"

function PageHeader({ handleClick, options, breadcrumbs }: PageHeaderProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledWrapper>
      <StyledHeader>
        <Container>
          <Grid container justify="space-between" alignItems="center">
            <PageBreadcrumbs onPrev={handleClick} breadcrumbs={breadcrumbs} />
            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                css={{ position: "relative" }}
              >
                <StyledDropdownMenu
                  isOpen={isOpen}
                  onChange={setIsOpen}
                  options={options}
                >
                  <Button
                    isCircle
                    size="xs"
                    border={isOpen ? "brand" : undefined}
                    variant={isOpen ? "transparent" : "transparentWithBorder"}
                  >
                    <DotsIcon />
                  </Button>
                </StyledDropdownMenu>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </StyledHeader>
      <Divider />
    </StyledWrapper>
  )
}

export default observer(PageHeader)

const StyledDropdownMenu = styled(DropdownMenu)`
  .KUI-Popper {
    width: 254px;
  }
`

const StyledWrapper = styled.div`
  position: sticky;
  top: 64px;
  z-index: 5;
`

const StyledHeader = styled.div`
  padding: 12px 0;
  background: ${({ theme }) => theme.palette.background.light1};
`
