import React from "react"
import { ApartmentFillingListProps } from "./types"
import ApartmentControlledFillingSection from "../ApartmentControlledFillingSection"
import ApartmentNotControlledFillingSection from "../ApartmentNotControlledFillingSection"
import ApartmentFillingSection from "../ApartmentFillingSection"

const FillingSection = (props: ApartmentFillingListProps) => {
  const { isControlled, fillingList, type, ...other } = props

  if (fillingList)
    return (
      <ApartmentFillingSection
        fillingList={fillingList[type as keyof typeof fillingList]}
        {...other}
      />
    )

  if (isControlled)
    return <ApartmentControlledFillingSection type={type} {...other} />

  return <ApartmentNotControlledFillingSection type={type} {...other} />
}

export default FillingSection
