import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import {
  SortingFilter,
  Loader,
  Paginator,
  getPhoneParamsFromString,
} from "kui-utils"
import {
  ApartmentLinkModel,
  ApartmentLiteModel,
  FetchApartmentsResponse,
  FetchApartmentOwnersResponse,
} from "kui-crm/types"
import { ClientLiteModel, ObjectForMapModel } from "kui-crm"
import {
  ApartmentLinkParams,
  ApartmentLiteParams,
} from "../../types/store/apartments"
import ApartmentsAgent from "../../agent/Apartments"
import { NewApartmentFormParams } from "../../components/forms/apartment/ApartmentCreationForm/types"
import ClientsLiteStore from "./ClientsLiteStore"

class ApartmentsStore {
  apartments: ApartmentLiteParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.apartments = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter(undefined)
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<FetchApartmentsResponse>(
      ApartmentsAgent.all(
        this.paginator.offset,
        this.paginator.limit,
        `${this.filter.filterParams || ""}&is_active=true`
      )
    )

    runInAction(() => {
      if (!err && res) {
        const mapper = (apartment: ApartmentLiteModel) =>
          ApartmentsStore.getApartmentParams(apartment)

        this.apartments = this.paginator.getPageResponse<
          ApartmentLiteModel,
          ApartmentLiteParams
        >(res, this.apartments, mapper)
      } else {
        this.loader.setError("fetch apartments", err)
      }
      this.loader.endLoading()
    })
  }

  createApartment = async (data: NewApartmentFormParams) => {
    this.creationLoader.startLoading()

    const body = ApartmentsStore.getCreationBody(data)

    const [err, res] = await to<ApartmentLiteModel>(
      ApartmentsAgent.create(body)
    )

    runInAction(() => {
      if (!err && res) {
        this.apartments = [
          ApartmentsStore.getApartmentParams(res),
          ...this.apartments,
        ]
        this.paginator.clearOffset()
        this.paginator.startFetching()
      } else {
        this.creationLoader.setError("apartment creation", err)
      }
      this.creationLoader.endLoading()
    })

    return this.apartments[0]
  }

  findApartmentById = (apartmentId: number) =>
    this.apartments.find((apartment) => apartment.id === apartmentId)

  static getApartmentParams = (apartment: ApartmentLiteModel) => ({
    id: apartment.id,
    address: apartment.object_name || apartment.address,
    apartmentNumber: apartment.apartment_number || "",
    mainImage: apartment.main_image?.image_small_url || null,
    serviceContractStartDate: apartment.service_contract?.start_date
      ? DateTime.fromISO(apartment.service_contract.start_date)
      : null,
    initialCommission: apartment.initial_commission,
    maxMetersCount: {
      ...(apartment.max_gas_counters && { gas: apartment.max_gas_counters }),
      ...(apartment.max_electricity_counters && {
        electricity: apartment.max_electricity_counters,
      }),
      ...(apartment.max_heating_counters && {
        heating: apartment.max_heating_counters,
      }),
      ...(apartment.max_water_counters && {
        water: apartment.max_water_counters,
      }),
    },
    metersPayers: {
      ...(apartment.gas_counters_paid_by && {
        gas: apartment.gas_counters_paid_by,
      }),
      ...(apartment.electricity_counters_paid_by && {
        electricity: apartment.electricity_counters_paid_by,
      }),
      ...(apartment.heating_counters_paid_by && {
        heating: apartment.heating_counters_paid_by,
      }),
      ...(apartment.water_counters_paid_by && {
        water: apartment.water_counters_paid_by,
      }),
    },
    operatingAccounts: apartment.operating_accounts,
    administrativeCompany: {
      ...apartment.administrative_company,
      operatingAccount: apartment.payer_code,
    },
    payerCode: apartment.payer_code,
    folderNumber: apartment.folder_number,
    communalServicesAccount: apartment.financial_personal_account,
    hasRentalContract: !!apartment.renter,
    hasInitialAppraisal: apartment.has_first_appraisal,
    hasServiceContract: !!apartment.service_contract,
    rentalContractStatus: apartment.rental_status,
  })

  static getApartmentResources = async (id: number) => {
    const [, res] = await to(ApartmentsAgent.getApartmentResources(id))
    return res
  }

  static getCreationBody = (data: NewApartmentFormParams) => ({
    address: data.apartment.address,
    apartment_number: data.apartmentNumber,
    floor: data.floor,
    owner_id: data.owner.id!,
    city: data.city?.id,
    post_index: data.zipCode,
    folder_number: data.folderNumber,
  })

  static getApartmentOwners = async (id: number, loader: Loader) => {
    loader.startLoading()

    const [err, res] = await to<FetchApartmentOwnersResponse>(
      ApartmentsAgent.getApartmentOwners(id)
    )
    if (err) {
      loader.setError("fetch owners", err)
    }

    loader.endLoading()

    return res?.map((owner) => ClientsLiteStore.getClientLiteParams(owner))
  }

  static getApartmentLinkParams = (
    apartment: ApartmentLinkModel,
    withRenter?: boolean
  ): ApartmentLinkParams => ({
    id: apartment.id,
    address: apartment.object_name || apartment.address,
    previewImage: apartment.main_image?.image_small_url,
    folderNumber: apartment.folder_number,
    hasRentalContract: withRenter,
  })

  static getClientParams = (client: ClientLiteModel | null) => {
    if (!client) return null

    return {
      id: client.id,
      phoneNumber: getPhoneParamsFromString(client.phone),
      fullName: client.full_name,
    }
  }

  static getObjectForMapParams = (apartment: ObjectForMapModel) => ({
    id: apartment.id,
    apartment: {
      ...ApartmentsStore.getApartmentLinkParams(apartment),
      roomsCount: null,
    },
    tenant: ApartmentsStore.getClientParams(apartment.renter),
    landlord: ApartmentsStore.getClientParams(apartment.owner),
    location: {
      lat: apartment.geolocation?.lat,
      lng: apartment.geolocation?.lon,
    },
    metroStations: apartment.metro_stations?.map((station) => ({
      color: `#${station.line.hex_color}`,
      name: station.name,
    })),
  })
}

export default ApartmentsStore
