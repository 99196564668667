import * as React from "react"
import { useFieldArray } from "react-hook-form"
import { ApartmentFillingSectionProps } from "./types"
import { FillingParams } from "../ApartmentFillingCard/types"
import ApartmentFillingSection from "../ApartmentFillingSection"

function ApartmentNotControlledFillingSection<T extends FillingParams>(
  props: ApartmentFillingSectionProps<T>
) {
  const { form, type, ...other } = props
  const { fields } = useFieldArray({
    control: form!.control,
    name: `fillingList.${type}`,
    keyName: "fieldId",
  })

  // @ts-ignore
  return <ApartmentFillingSection form={form} fillingList={fields} {...other} />
}

export default ApartmentNotControlledFillingSection
