import React from "react"
import { observer } from "mobx-react"
import ApartmentFillingList from "../../../../../../components/forms/apartmentFilling/FillingList"
import { InspectionNotEditableFillingListProps } from "./types"
import useInspectionStore from "../../store"

const InspectionNotEditableFillingList = (
  props: InspectionNotEditableFillingListProps
) => {
  const { form } = props
  const { editor } = useInspectionStore()

  return <ApartmentFillingList disabled={!editor.isEditing} form={form} />
}

export default observer(InspectionNotEditableFillingList)
