import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import { EditingFillingModalProps } from "./types"
import FillingInfoForm from "../../../../forms/filling/FillingInfoForm"
import { FillingInfoFormFields } from "../../../../forms/filling/FillingInfoForm/types"
import { FillingParams } from "../../../../../../components/forms/apartmentFilling/FillingList/ApartmentFillingCard/types"

function FillingEditingModal<T extends FillingParams>(
  props: EditingFillingModalProps<T>
) {
  const { index, handleClose, open, filling, handleSubmit } = props
  const defaultValues = {
    id: filling.id,
    name: filling.name,
    description: filling.description,
    tag: filling.tag,
    images: filling.images,
    serialNumber: filling.serialNumber,
    numberOfSubjects: filling.numberOfSubjects,
    instruction: filling.instruction,
    type: filling.type,
  }

  const handleFormSubmit = (data: FillingInfoFormFields) => {
    handleClose()
    handleSubmit(data, filling, index)
  }

  return (
    <Modal title={`Edit ${filling.type}`} open={open} handleClose={handleClose}>
      <FillingInfoForm
        type={filling.type}
        variant="edit"
        handleSubmit={handleFormSubmit}
        defaultValues={defaultValues}
      />
    </Modal>
  )
}

export default observer(FillingEditingModal)
