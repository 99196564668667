import React from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { DotsIcon } from "kui-icon"
import { MenuButtonProps } from "./types"
import DropdownMenu from "../../ui/DropdownMenu"

function MenuButton({ options, disabled, ...otherProps }: MenuButtonProps) {
  return (
    <StyledWrapper {...otherProps}>
      <StyledDropdownMenu options={options} disabled={disabled}>
        <Button isCircle size="xs" variant="transparent" data-testid="menu_btn">
          <StyledDotsIcon width={15} />
        </Button>
      </StyledDropdownMenu>
    </StyledWrapper>
  )
}

export default MenuButton

const StyledWrapper = styled.div`
  position: relative;
  text-align: right;
`

const StyledDotsIcon = styled(DotsIcon)`
  transform: rotate(90deg);
`

const StyledDropdownMenu = styled(DropdownMenu)`
  .KUI-Popper {
    width: 254px;
  }
`
