import * as React from "react"
import { Grid, Button } from "kui-basic"
import styled from "@emotion/styled"
import ClosePeriodButton from "../ClosePeriodButton"
import PageFooter from "../../../../../../../../components/ui/PageFooter"
import DownloadReportButton from "../DownloadReportButton"
import DownloadDepositFileButton from "../CalcDepositButton"
import CreateNewInvoiceButton from "../CreateNewInvoiceButton"

function ExpensesFooter() {
  return (
    <PageFooter>
      <Grid item xs={6} container spacing={3}>
        <Grid item>
          <StyledButton size="s" variant="white">
            Bank register
          </StyledButton>
        </Grid>
        <DownloadReportButton />
        <DownloadDepositFileButton />
      </Grid>
      <Grid item xs={6} container spacing={3} justify="flex-end">
        <CreateNewInvoiceButton />
        <ClosePeriodButton />
      </Grid>
    </PageFooter>
  )
}

export default ExpensesFooter

const StyledButton = styled(Button)`
  p {
    color: ${({ theme }) => theme.palette.grey.seventy};
  }
`
