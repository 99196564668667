import React from "react"
import ApartmentFillingList from "../../../../../../components/forms/apartmentFilling/FillingList"
import useInspectionStore from "../../store"
import { InspectionFillingListProps } from "./types"

const InspectionEditableFillingList = (props: InspectionFillingListProps) => {
  const { form } = props
  const { editor } = useInspectionStore()

  return (
    <ApartmentFillingList
      isControlled
      form={form}
      disabled={!editor.isEditing}
    />
  )
}

export default InspectionEditableFillingList
