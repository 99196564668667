import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Tooltip } from "kui-crm"
import { DropdownMenuProps } from "./types"
import DropdownOption from "./DropdownOption"

function DropdownMenu(props: DropdownMenuProps) {
  const {
    children,
    options,
    className,
    isOpen: defaultOpen,
    onChange,
    disabled,
    ...other
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    if (isOpen) {
      if (onChange) onChange(false)
      setIsOpen(false)
    } else {
      if (onChange) onChange(true)
      setIsOpen(true)
    }
  }

  const closeTooltip = () => {
    if (onChange) onChange(false)
    else setIsOpen(false)
  }

  const handleChange = (value: boolean) => {
    if (onChange) onChange(value)
    setIsOpen(value)
  }

  const handleOptionClick = (callback: () => void) => {
    callback()
    closeTooltip()
  }

  useEffect(() => {
    if (typeof defaultOpen === "boolean") {
      setIsOpen(defaultOpen)
    }
  }, [defaultOpen])

  return (
    <StyledTooltip
      placement="bottom-end"
      toggleMethod="controlled"
      className={className}
      open={isOpen}
      onChange={handleChange}
      strategy="fixed"
      content={
        <div data-testid="menu_dropdown" {...other}>
          {options?.map((option) => (
            <DropdownOption
              key={option.label}
              option={option}
              handleOptionClick={handleOptionClick}
            />
          ))}
        </div>
      }
    >
      <StyledContent onClick={disabled ? undefined : toggleOpen}>
        {React.cloneElement(children, { disabled })}
      </StyledContent>
    </StyledTooltip>
  )
}

export default observer(DropdownMenu)

const StyledTooltip = styled(Tooltip)`
  .KUI-Popper {
    padding-top: 8px;
    transition: opacity ease-out 0.3s;
    > div {
      padding: 8px;
    }
  }
`

const StyledContent = styled.div`
  width: fit-content;
`
