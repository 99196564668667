import { DateTime } from "luxon"
import to from "await-to-js"
import { toNumber } from "kui-utils"
import { APISubjectRoles, FileBodyRequest } from "kui-crm/types"
import { ServiceCreationFields } from "../../../components/forms/apartmentExpenses/ServiceCreationForm/types"
import { transformToSnakeCase } from "../../../utils/service/mapper"
import { matchesAPISubjectRoles } from "../../../utils/content/matches"
import { SubjectRoles } from "../../../types/common"
import { ApartmentLinkParams } from "../../../types/store/apartments"
import {
  CheckCompanyIsNewResponse,
  LastOpenPeriodModel,
  PostServiceRequest,
} from "../../../types/api/apartmentExpenses"
import ApartmentServicesAgent from "../../../agent/ApartmentServices"
import { LastOpenPeriodParams } from "../../../types/store/apartmentExpenses"

class ApartmentServicesStore {
  static checkIsNewCompanyForApartment = async (
    companyId: number,
    periodId: number,
    apartmentId: number
  ) => {
    const body = {
      company_id: companyId,
    }

    const [err, res] = await to<CheckCompanyIsNewResponse>(
      ApartmentServicesAgent.checkCompanyIsNew(apartmentId, periodId, body)
    )

    if (!err && res) {
      return [null, res.is_new]
    }

    return [err, null]
  }

  static getLastOpenedPeriod = async (
    apartmentId: number
  ): Promise<[Error | null, LastOpenPeriodParams | undefined]> => {
    const [err, res] = await to<LastOpenPeriodModel>(
      ApartmentServicesAgent.getLastOpenPeriod(apartmentId)
    )

    if (res?.year && res?.month) {
      const period = DateTime.fromObject({
        month: res.month,
        year: res.year,
        day: 1,
      })
      return [null, { id: res.id, withRenter: !!res.renter, period }]
    }
    if (res && !err) {
      return [null, { id: res.id, withRenter: !!res.renter, period: null }]
    }

    return [err, undefined]
  }

  static generatePurpose = (
    dateOfIssue: DateTime,
    apartment: ApartmentLinkParams
  ) => {
    const { address, payerCode, apartmentNumber } = apartment
    if (!payerCode) {
      return {
        message: "Для генерации назначения заполните код плательщика квартиры",
        err: true,
      }
    }
    if (!address) {
      return {
        message: "Для генерации назначения заполните адрес квартиры",
        err: true,
      }
    }
    const localeDate = dateOfIssue.setLocale("ru")
    return {
      message: `Оплата по коду плательщика №${payerCode} за ${localeDate.monthLong} ${localeDate.year}, адрес: ${address}, квартира №${apartmentNumber}, без НДС`,
      err: false,
    }
  }

  static getPostServiceBody = (
    data: ServiceCreationFields,
    invoice: FileBodyRequest | null
  ) =>
    ({
      ...transformToSnakeCase(data),
      payment_made_by: matchesAPISubjectRoles[
        data.paymentMadeBy as SubjectRoles
      ] as APISubjectRoles,
      refund_from: matchesAPISubjectRoles[
        data.refundFrom as SubjectRoles
      ] as APISubjectRoles,
      company: data.company?.id,
      company_account: data.companyBankAccount?.id,
      value: toNumber(data.price)?.toString(),
      period_info: {
        duration_type: data.durationType,
        charge_from: data.chargeFrom
          ? DateTime.fromJSDate(data.chargeFrom).toFormat("MM.yyyy")
          : "",
        charge_till: data.chargeTill
          ? DateTime.fromJSDate(data.chargeTill).toFormat("MM.yyyy")
          : null,
      },
      file_number: data.documentNumber,
      invoice,
    } as PostServiceRequest)
}

export default ApartmentServicesStore
