import * as yup from "yup"

const ServiceFieldsSchema = yup.object().shape({
  name: yup.string().trim().required("This field is required"),
  chargeFrom: yup.date().required("This field is required"),
  durationType: yup.string().required("This field is required"),
  chargeTill: yup
    .date()
    .nullable()
    .when("durationType", {
      is: (value: string) => value && value === "period",
      then: (rule) => rule.required("This field is required"),
    }),
})

export default ServiceFieldsSchema
