import * as yup from "yup"

const ServiceFieldsSchema = yup.object().shape({
  purpose: yup.string().trim().required("This field is required"),
  price: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
})

export default ServiceFieldsSchema
