import React from "react"
import { observer } from "mobx-react"
import { Button, Grid } from "kui-basic"
import { PlusIcon } from "kui-icon"
import useExpensesStore from "../../../../store"

function CreateNewInvoiceButton() {
  const { overviewStore, summaryStore, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore

  const handleClick = async () => {
    if (overviewStore.id && selectedPeriod?.id) {
      summaryStore.createNewInvoice(overviewStore.id, selectedPeriod.id)
    }
  }

  return (
    <Grid item>
      <Button
        size="s"
        disabled={
          selectedPeriod?.isClosed || selectedPeriod?.hasAllInvoicesConfirmed
        }
        startIcon={<PlusIcon />}
        onClick={handleClick}
        data-testid="createNewInvoiceButton"
      >
        Create new invoice
      </Button>
    </Grid>
  )
}

export default observer(CreateNewInvoiceButton)
